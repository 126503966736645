import React, { useState, useEffect, useCallback } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import apiPayment from '../../services/apiPayment';
import * as S from './styles';
import Accounts from '../../components/Accounts';

export default function Account() {
  const [accounts, setAccounts] = useState([]);

  const initAccounts = useCallback(async () => {
    const { data } = await apiPayment.get(`subscriptions`);

    setAccounts(data);
  }, []);

  useEffect(() => {
    initAccounts();
  }, [initAccounts]);

  async function handleDelete(id) {
    Swal.fire({
      title: 'Tem certeza que deseja deletar essa conta de teste?',
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, delete!',
    }).then(async result => {
      if (result.isConfirmed) {
        await apiPayment.delete(`/subscriptions/${id}`);
        initAccounts();
        Swal.fire({
          title: 'Deletado!',
          text: 'A conta de teste foi deletada.',
          icon: 'success',
        });
      }
    });
  }

  return (
    <S.Container>
      <header>
        <strong>Contas de Testes</strong>
        <Link to="/contas/create">
          <button type="button">
            <MdAddCircleOutline color="#fff" size={20} />
            Adicionar Conta
          </button>
        </Link>
      </header>
      <br />
      <br />
      <Accounts accounts={accounts} onDelete={handleDelete} />
    </S.Container>
  );
}
