/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import PropTypes from 'prop-types';

function AccountForm({ onSubmit }) {
  async function handleSubmit(value) {
    await onSubmit({ ...value });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <label htmlFor="external_reference">E-mail</label>
      <Input
        name="external_reference"
        id="external_reference"
        type="text"
        required
        placeholder="E-mail"
      />

      <Input
        name="added_by_admin"
        id="added_by_admin"
        type="text"
        hidden
        value="true"
      />

      <Input name="status" id="status" type="text" hidden value="authorized" />

      <button type="submit">Salvar</button>
    </Form>
  );
}

export default AccountForm;

AccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
