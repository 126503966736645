import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Signin from '../pages/Signin';
import Dashboard from '../pages/Dashboard';
import Arena from '../pages/Arena';
import Camera from '../pages/Camera';
import CameraCreate from '../pages/CameraCreate';
import Court from '../pages/Court';
import CourtsCreate from '../pages/CourtCreate';
import ArenaEdit from '../pages/ArenaEdit';
import CourtEdit from '../pages/CourtEdit';
import CameraEdit from '../pages/CameraEdit';
import Account from '../pages/Account';
import AccountCreate from '../pages/AccountCreate';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Signin} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/camera" component={Camera} isPrivate />
      <Route path="/quadra" component={Court} isPrivate />
      <Route path="/conta" component={Account} isPrivate />

      <Route path="/cameras/create" component={CameraCreate} isPrivate />
      <Route path="/quadras/create" component={CourtsCreate} isPrivate />
      <Route path="/arenas/create" component={Arena} isPrivate />
      <Route path="/contas/create" component={AccountCreate} isPrivate />

      <Route path="/arenas/edit/:id" component={ArenaEdit} isPrivate />
      <Route path="/cameras/edit/:id" component={CameraEdit} isPrivate />
      <Route path="/quadras/edit/:id" component={CourtEdit} isPrivate />
    </Switch>
  );
}
