import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Container } from './styles';
import apiPayment from '../../services/apiPayment';
import AccountForm from '../../components/AccountForm';

export default function AccountCreate({ history }) {
  async function handleSubmit(value) {
    try {
      await apiPayment.post(`/subscriptions`, { ...value });

      history.push('/conta');
    } catch (error) {
      console.tron.log(error);
      toast.error(`Error na criação da conta`);
    }
  }
  return (
    <Container>
      <AccountForm onSubmit={handleSubmit} />
    </Container>
  );
}

AccountCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
