import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Container } from './styles';
import api from '../../services/api';
import CameraForm from '../../components/CameraForm';

export default function CameraEdit({ history, match }) {
  const { id } = match.params;

  const [courts, setCourts] = useState([]);

  const initCourts = useCallback(async () => {
    const response = await api.get(`courts`);
    const { courts: courtsResponse } = response.data._embedded;

    setCourts(courtsResponse);
  }, []);

  useEffect(() => {
    initCourts();
  }, [initCourts]);

  const [camera, setCamera] = useState({});

  useEffect(() => {
    async function loadCamera() {
      const response = await api.get(`cameras/${id}`);
      setCamera(response.data);
    }

    loadCamera();
  }, [id]);

  async function handleSubmit(value) {
    try {
      await api.patch(`/cameras/${id}`, { ...value });

      history.push('/camera');
    } catch (error) {
      console.tron.log(error);
      toast.error(`Error na edição da camera`);
    }
  }
  return (
    <Container>
      <CameraForm onSubmit={handleSubmit} camera={camera} courts={courts} />
    </Container>
  );
}

CameraEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};
